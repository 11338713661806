import { useRuntimeConfig } from '#imports'

export function useEnvironment() {
  const environment = useRuntimeConfig().public?.environment.toLowerCase()

  const isLocalEnv = environment === 'local'
  const isIntEnv = environment === 'int'
  const isProdEnv = environment === 'prod'

  return {
    isLocalEnv,
    isIntEnv,
    isProdEnv,
  }
}
