/**
 * To learn more about Link Resolving check out the Prismic documentation
 * https://prismic.io/docs/vuejs/beyond-the-api/link-resolving
 */

import type { Content } from '@prismicio/client'

export default function (doc: Content.AllDocumentTypes) {
  if (doc.type === 'blog_page')
    return `/blog/${doc.uid}`

  if (doc.type === 'glossary_page')
    return `/glossary/${doc.uid}`

  if (doc.type === 'partner_page')
    return `/partners/${doc.uid}`

  if (doc.type === 'plans_page')
    return `/plans/${doc.uid}`

  if (doc.type === 'fair_use_page')
    return `/fair-use/${doc.uid}`

  if (doc.type === 'terms_of_service_page')
    return `/terms_of_service/${doc.uid}`

  if (doc.type === 'campaign_page')
    return `/campaigns/native/${doc.uid}`

  if (doc.type === 'simple_page')
    return `/${doc.uid}`

  return '/'
}
