<script lang="ts" setup>
import { EvButton, EvLogo } from '@kreait/web-components'
import type { NuxtError } from '#app'

defineProps<{
  error: NuxtError
}>()

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div class="min-h-screen bg-white-off">
    <section class="mx-auto min-h-screen max-w-xl px-4 pt-12 sm:px-6 lg:px-4">
      <div class="col-span-4 col-start-4 items-center justify-center px-4 text-center">
        <EvLogo class="mx-auto mt-12 w-36 text-primary-500" />
        <h1 class="text-primary mt-24 text-2xl font-semibold text-primary-500">
          {{ error.statusMessage || $t('errors.404.statusMessage') }}
        </h1>
        <p class="mt-12 text-primary-500">
          {{ error.message }}
        </p>
        <EvButton
          class="mt-8"
          @click="handleError"
        >
          {{ $t('errors.fatal.cta') }}
        </EvButton>
      </div>
    </section>
  </div>
</template>
