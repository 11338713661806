import * as Sentry from '@sentry/vue'
import { useEnvironment } from '~/composables/useEnvironment'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { isLocalEnv, isProdEnv } = useEnvironment()
  const { public: { sentry, tracingTargets } } = useRuntimeConfig()

  if (!sentry.dsn)
    return

  Sentry.init({
    enabled: !isLocalEnv,
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: isProdEnv ? 0.1 : 1.0,
    tracePropagationTargets: tracingTargets,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  })
})
