import revive_payload_client_jQdmFtTqlj from "/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.1_sass@1.72.0_t_vi25yzhot56ulkiudnhsbzvsgy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9SckB8ZIFJ from "/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.1_sass@1.72.0_t_vi25yzhot56ulkiudnhsbzvsgy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3Na3vg38A3 from "/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.1_sass@1.72.0_t_vi25yzhot56ulkiudnhsbzvsgy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_TrxYupQEqU from "/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.1_sass@1.72.0_t_vi25yzhot56ulkiudnhsbzvsgy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_zrvhfHEzrS from "/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.1_sass@1.72.0_t_vi25yzhot56ulkiudnhsbzvsgy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/builds/kreait/elvah/frontend-services/landing-page-next/.nuxt/components.plugin.mjs";
import prefetch_client_HtpvyBIv1o from "/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.1_sass@1.72.0_t_vi25yzhot56ulkiudnhsbzvsgy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_41PBeuWQUM from "/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.14.1_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_qeC2fMQOC4 from "/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/@nuxtjs+prismic@3.1.1_rollup@4.14.1_vue@3.4.21/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.mjs";
import plugin_Dyqo3W9IUW from "/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/@nuxtjs+prismic@3.1.1_rollup@4.14.1_vue@3.4.21/node_modules/@nuxtjs/prismic/dist/runtime/plugin.mjs";
import chunk_reload_client_QW4FNI1n3V from "/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.59.1_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.1_sass@1.72.0_t_vi25yzhot56ulkiudnhsbzvsgy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import scroll_client_HJUo2UKx9n from "/builds/kreait/elvah/frontend-services/landing-page-next/plugins/scroll.client.ts";
import sentry_client_shVUlIjFLk from "/builds/kreait/elvah/frontend-services/landing-page-next/plugins/sentry.client.ts";
import version_client_0D8DwfTb7v from "/builds/kreait/elvah/frontend-services/landing-page-next/plugins/version.client.ts";
export default [
  revive_payload_client_jQdmFtTqlj,
  unhead_9SckB8ZIFJ,
  router_3Na3vg38A3,
  payload_client_TrxYupQEqU,
  check_outdated_build_client_zrvhfHEzrS,
  components_plugin_KR1HBZs4kY,
  prefetch_client_HtpvyBIv1o,
  i18n_41PBeuWQUM,
  plugin_client_qeC2fMQOC4,
  plugin_Dyqo3W9IUW,
  chunk_reload_client_QW4FNI1n3V,
  scroll_client_HJUo2UKx9n,
  sentry_client_shVUlIjFLk,
  version_client_0D8DwfTb7v
]