import { default as previewO4N80vDOW5Meta } from "/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/@nuxtjs+prismic@3.1.1_rollup@4.14.1_vue@3.4.21/node_modules/@nuxtjs/prismic/dist/runtime/preview.vue?macro=true";
import { default as _91uid_93rFJNkK9JiRMeta } from "/builds/kreait/elvah/frontend-services/landing-page-next/pages/[uid].vue?macro=true";
import { default as about_45us7vQJoMBl5KMeta } from "/builds/kreait/elvah/frontend-services/landing-page-next/pages/about-us.vue?macro=true";
import { default as _91uid_93wB9Pdp2U2HMeta } from "/builds/kreait/elvah/frontend-services/landing-page-next/pages/blog/[uid].vue?macro=true";
import { default as indexMeTGN4owIfMeta } from "/builds/kreait/elvah/frontend-services/landing-page-next/pages/blog/index.vue?macro=true";
import { default as careersFsq9gBZdjiMeta } from "/builds/kreait/elvah/frontend-services/landing-page-next/pages/careers.vue?macro=true";
import { default as _91uid_93uZLOKT9v4oMeta } from "/builds/kreait/elvah/frontend-services/landing-page-next/pages/glossary/[uid].vue?macro=true";
import { default as indexs6J1Nt22V5Meta } from "/builds/kreait/elvah/frontend-services/landing-page-next/pages/glossary/index.vue?macro=true";
import { default as index5hdtsJLyyxMeta } from "/builds/kreait/elvah/frontend-services/landing-page-next/pages/index.vue?macro=true";
import { default as _91_91uid_93_93KdyTLnbz2PMeta } from "/builds/kreait/elvah/frontend-services/landing-page-next/pages/plans/[[uid]].vue?macro=true";
import { default as slice_45simulatorpWCR3t7Mk3Meta } from "/builds/kreait/elvah/frontend-services/landing-page-next/pages/slice-simulator.vue?macro=true";
import { default as _91_91uid_93_93WBRifNiPxBMeta } from "/builds/kreait/elvah/frontend-services/landing-page-next/pages/terms-of-service/[[uid]].vue?macro=true";
export default [
  {
    name: previewO4N80vDOW5Meta?.name ?? "prismic-preview___de",
    path: previewO4N80vDOW5Meta?.path ?? "/preview",
    meta: previewO4N80vDOW5Meta || {},
    alias: previewO4N80vDOW5Meta?.alias || [],
    redirect: previewO4N80vDOW5Meta?.redirect,
    component: () => import("/builds/kreait/elvah/frontend-services/landing-page-next/node_modules/.pnpm/@nuxtjs+prismic@3.1.1_rollup@4.14.1_vue@3.4.21/node_modules/@nuxtjs/prismic/dist/runtime/preview.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93rFJNkK9JiRMeta?.name ?? "uid___de",
    path: _91uid_93rFJNkK9JiRMeta?.path ?? "/:uid()",
    meta: _91uid_93rFJNkK9JiRMeta || {},
    alias: _91uid_93rFJNkK9JiRMeta?.alias || [],
    redirect: _91uid_93rFJNkK9JiRMeta?.redirect,
    component: () => import("/builds/kreait/elvah/frontend-services/landing-page-next/pages/[uid].vue").then(m => m.default || m)
  },
  {
    name: about_45us7vQJoMBl5KMeta?.name ?? "about-us___de",
    path: about_45us7vQJoMBl5KMeta?.path ?? "/about-us",
    meta: about_45us7vQJoMBl5KMeta || {},
    alias: about_45us7vQJoMBl5KMeta?.alias || [],
    redirect: about_45us7vQJoMBl5KMeta?.redirect,
    component: () => import("/builds/kreait/elvah/frontend-services/landing-page-next/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93wB9Pdp2U2HMeta?.name ?? "blog-uid___de",
    path: _91uid_93wB9Pdp2U2HMeta?.path ?? "/blog/:uid()",
    meta: _91uid_93wB9Pdp2U2HMeta || {},
    alias: _91uid_93wB9Pdp2U2HMeta?.alias || [],
    redirect: _91uid_93wB9Pdp2U2HMeta?.redirect,
    component: () => import("/builds/kreait/elvah/frontend-services/landing-page-next/pages/blog/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexMeTGN4owIfMeta?.name ?? "blog___de",
    path: indexMeTGN4owIfMeta?.path ?? "/blog",
    meta: indexMeTGN4owIfMeta || {},
    alias: indexMeTGN4owIfMeta?.alias || [],
    redirect: indexMeTGN4owIfMeta?.redirect,
    component: () => import("/builds/kreait/elvah/frontend-services/landing-page-next/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: careersFsq9gBZdjiMeta?.name ?? "careers___de",
    path: careersFsq9gBZdjiMeta?.path ?? "/careers",
    meta: careersFsq9gBZdjiMeta || {},
    alias: careersFsq9gBZdjiMeta?.alias || [],
    redirect: careersFsq9gBZdjiMeta?.redirect,
    component: () => import("/builds/kreait/elvah/frontend-services/landing-page-next/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93uZLOKT9v4oMeta?.name ?? "glossary-uid___de",
    path: _91uid_93uZLOKT9v4oMeta?.path ?? "/glossary/:uid()",
    meta: _91uid_93uZLOKT9v4oMeta || {},
    alias: _91uid_93uZLOKT9v4oMeta?.alias || [],
    redirect: _91uid_93uZLOKT9v4oMeta?.redirect,
    component: () => import("/builds/kreait/elvah/frontend-services/landing-page-next/pages/glossary/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexs6J1Nt22V5Meta?.name ?? "glossary___de",
    path: indexs6J1Nt22V5Meta?.path ?? "/glossary",
    meta: indexs6J1Nt22V5Meta || {},
    alias: indexs6J1Nt22V5Meta?.alias || [],
    redirect: indexs6J1Nt22V5Meta?.redirect,
    component: () => import("/builds/kreait/elvah/frontend-services/landing-page-next/pages/glossary/index.vue").then(m => m.default || m)
  },
  {
    name: index5hdtsJLyyxMeta?.name ?? "index___de",
    path: index5hdtsJLyyxMeta?.path ?? "/",
    meta: index5hdtsJLyyxMeta || {},
    alias: index5hdtsJLyyxMeta?.alias || [],
    redirect: index5hdtsJLyyxMeta?.redirect,
    component: () => import("/builds/kreait/elvah/frontend-services/landing-page-next/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91uid_93_93KdyTLnbz2PMeta?.name ?? "plans-uid___de",
    path: _91_91uid_93_93KdyTLnbz2PMeta?.path ?? "/plans/:uid?",
    meta: _91_91uid_93_93KdyTLnbz2PMeta || {},
    alias: _91_91uid_93_93KdyTLnbz2PMeta?.alias || [],
    redirect: _91_91uid_93_93KdyTLnbz2PMeta?.redirect,
    component: () => import("/builds/kreait/elvah/frontend-services/landing-page-next/pages/plans/[[uid]].vue").then(m => m.default || m)
  },
  {
    name: slice_45simulatorpWCR3t7Mk3Meta?.name ?? "slice-simulator___de",
    path: slice_45simulatorpWCR3t7Mk3Meta?.path ?? "/slice-simulator",
    meta: slice_45simulatorpWCR3t7Mk3Meta || {},
    alias: slice_45simulatorpWCR3t7Mk3Meta?.alias || [],
    redirect: slice_45simulatorpWCR3t7Mk3Meta?.redirect,
    component: () => import("/builds/kreait/elvah/frontend-services/landing-page-next/pages/slice-simulator.vue").then(m => m.default || m)
  },
  {
    name: _91_91uid_93_93WBRifNiPxBMeta?.name ?? "terms-of-service-uid___de",
    path: _91_91uid_93_93WBRifNiPxBMeta?.path ?? "/terms-of-service/:uid?",
    meta: _91_91uid_93_93WBRifNiPxBMeta || {},
    alias: _91_91uid_93_93WBRifNiPxBMeta?.alias || [],
    redirect: _91_91uid_93_93WBRifNiPxBMeta?.redirect,
    component: () => import("/builds/kreait/elvah/frontend-services/landing-page-next/pages/terms-of-service/[[uid]].vue").then(m => m.default || m)
  }
]